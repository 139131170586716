import { EthereumWalletConnectors } from '@dynamic-labs/ethereum';
import { DynamicContextProvider } from '@dynamic-labs/sdk-react-core';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';

import { registerWithDynamic } from '@/api/api';
import { useAppDispatch } from '@/hooks/redux';
import { setIsAuthenticated } from '@/redux/auth/authSlice';
import { setCookie } from '@/utils/cookies';

interface IDynamicProvider {
  children: React.ReactNode;
}

const DynamicProvider: React.FC<IDynamicProvider> = ({ children }) => {
  const searchParams = useSearchParams();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const channelId = searchParams.get('channel');
  const inviteCode = searchParams.get('invite');

  return (
    <DynamicContextProvider
      settings={{
        environmentId: process.env.NEXT_PUBLIC_DYNAMIC_LABS_ENVIROMENT_ID,
        walletConnectors: [EthereumWalletConnectors],
        initialAuthenticationMode: 'connect-and-sign',
        recommendedWallets: [
          { walletKey: 'metamask', label: 'Popular' },
          { walletKey: 'phantomevm', label: 'Popular' },
          { walletKey: 'coinbase', label: 'Popular' },
        ],
        events: {
          onAuthSuccess: async (args) => {
            if (args.user.email) {
              const res = await registerWithDynamic({
                email: args.user.email,
              });
              const authToken = localStorage.getItem('dynamic_min_authentication_token');
              if (!authToken) return;
              setCookie('_token', authToken, { sameSite: 'none', secure: true });
              dispatch(setIsAuthenticated(true));
              if (res.message === 'created') {
                await router.push('/profile-setup');
              } else {
                router.push('/portfolio');
              }
              if (inviteCode) {
                await router.push(`/channel/invite/${inviteCode}`);
              } else if (channelId) {
                await router.push(`/channel/${channelId}`);
              }
            }
          },
        },
      }}
    >
      {children}
    </DynamicContextProvider>
  );
};

export default DynamicProvider;
